import FundSecure from './FundSecure'
import TwoFactor from './TwoFactor'
import Recommendations from './Recommendations'
import PageTop from './PageTop'

function getData (componentName, intl) {
  return ComponentsData[componentName](intl)
}

const ComponentsData = {
  FundSecure: FundSecure,
  TwoFactor: TwoFactor,
  Recommendations: Recommendations,
  PageTop: PageTop
}

export default getData
