import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text, Container, Row, Col } from 'atomize-jnh'
import computerSvg from '../../../assets/desktop-computer-secure.svg'

export default function Recommendations (props) {
  Recommendations.propTypes = {
    title: PropTypes.string,
    messageLeft: PropTypes.string,
    messageRight: PropTypes.string,
    recommendations: PropTypes.array
  }
  const { title, messageLeft, messageRight, recommendations } = props
  const colMdSize = index => (index === 0 || recommendations.length - 1 === index ? '3' : '6')
  const textMargin = index => index === 1 && '24px auto'
  return (
    <Div p={{ t: '60px', b: '80px' }}>
      <Container>
        <Text textSize="heading" textAlign="center" textColor="#4D68F0">
          {title}
        </Text>
        <Row p={{ t: '36px', b: '128px' }}>
          <Col size={{ xs: '12', md: '3' }}>
            <Text
              p={{ t: '86px' }}
              textColor="#6B7C93"
              fontWeight="400"
              textSize="subtitle"
              textAlign="center">
              {messageLeft}
            </Text>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Div
              align="center"
              w="100%"
              h={{ xs: '12rem', md: '17rem', lg: '19rem' }}
              bgImg={computerSvg}
              m={{ t: { xs: '2rem', sm: '0', md: '0', lg: 0 } }}
              bgSize="contain"
              bgRepeat="no-repeat"
            />
          </Col>
          <Col size={{ xs: '12', md: '3' }}>
            <Text
              p={{ t: { xs: '48px', md: '86px' } }}
              textColor="#6B7C93"
              fontWeight="400"
              textSize="subtitle"
              textAlign="center">
              {messageRight}
            </Text>
          </Col>
        </Row>
        <Div />
        <Row>
          {recommendations.map((col, index) => {
            return (
              <Col
                size={{
                  xs: '12',
                  md: colMdSize(index)
                }}
                key={index}>
                <Div maxW={{ xs: '300px', md: '250px' }} m={{ xs: '24px auto', md: textMargin(index) }}>
                  <Text textColor="#6B7C93" textSize="body" textWeight="600">
                    {col.title}
                  </Text>
                  {col.paragraphs.map((value, index) => {
                    return (
                      <Text
                        key={index}
                        textColor="#6B7C93"
                        textSize="body"
                        m={{ b: '24px', l: 'auto', r: 'auto' }}>
                        {value}
                      </Text>
                    )
                  })}
                </Div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Div>
  )
}
