import mainImage from '../../../../assets/security-top.svg'

const PageTop = intl => {
  return {
    title: intl.formatMessage({ id: 'security.title' }),
    subtitle: intl.formatMessage({ id: 'security.subtitle' }),
    img: mainImage
  }
}

export default PageTop
