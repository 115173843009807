import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text, Container, Row, Col } from 'atomize-jnh'
import identitySvg from '../../../assets/identity-id.svg'

export default function TwoFactor (props) {
  TwoFactor.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
  }
  const { title, subtitle } = props

  return (
    <Div bg="#E9F3FE" p={{ t: '120px', b: '96px' }}>
      <Container>
        <Row justify="center">
          <Col size={{ xs: '12', md: '7' }}>
            <Div m={{ t: '60px' }}>
              <Text textSize="heading" textColor="#4D68F0">
                {title}
              </Text>
              <Text textColor="#6B7C93" fontWeight="400" textSize="subtitle" textAlign="justify">
                {subtitle}
              </Text>
            </Div>
          </Col>

          <Col size={{ md: '5' }}>
            <Div
              align="center"
              w="100%"
              h={{ xs: '22rem', md: '17rem', lg: '16rem' }}
              bgImg={identitySvg}
              m={{ t: { xs: '2rem', sm: '0', md: '0', lg: 0 }, l: { md: '5rem' } }}
              bgSize="contain"
              bgRepeat="no-repeat"
            />
          </Col>
        </Row>
      </Container>
    </Div>
  )
}
