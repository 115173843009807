import Data from '../../../../assets/Data.svg'
import Shield from '../../../../assets/Shield.svg'
import Support from '../../../../assets/Support.svg'

const FundSecure = intl => {
  return {
    title: intl.formatMessage({ id: 'security.fundSecure.title' }),
    subtitle: intl.formatMessage({ id: 'security.fundSecure.subtitle' }),
    descriptions: [
      {
        icon: Data,
        description: intl.formatMessage({ id: 'security.fundSecure.d1' }),
        w: { xs: '120px' },
        h: { xs: '80px' },
        p: { t: { xs: '24px' } }
      },
      {
        icon: Shield,
        description: intl.formatMessage({ id: 'security.fundSecure.d2' }),
        w: { xs: '80px' },
        h: { xs: '110px' },
        p: { t: { xs: '12px' } }
      },
      {
        icon: Support,
        description: intl.formatMessage({ id: 'security.fundSecure.d3' }),
        w: { xs: '100px' },
        h: { xs: '100px' },
        p: { t: { xs: '12px' } }
      }
    ]
  }
}

export default FundSecure
