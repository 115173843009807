const Recommendations = intl => {
  return {
    title: intl.formatMessage({ id: 'security.recommendations.title' }),
    messageLeft: intl.formatMessage({ id: 'security.recommendations.messageLeft' }),
    messageRight: intl.formatMessage({ id: 'security.recommendations.messageRight' }),
    recommendations: [
      {
        title: intl.formatMessage({ id: 'security.recommendations.r1.title' }),
        paragraphs: [
          intl.formatMessage({ id: 'security.recommendations.r1.p1' }),
          intl.formatMessage({ id: 'security.recommendations.r1.p2' }),
          intl.formatMessage({ id: 'security.recommendations.r1.p3' })
        ]
      },
      {
        title: intl.formatMessage({ id: 'security.recommendations.r2.title' }),
        paragraphs: [
          intl.formatMessage({ id: 'security.recommendations.r2.p1' }),
          intl.formatMessage({ id: 'security.recommendations.r2.p2' }),
          intl.formatMessage({ id: 'security.recommendations.r2.p3' })
        ]
      },
      {
        title: intl.formatMessage({ id: 'security.recommendations.r3.title' }),
        paragraphs: [
          intl.formatMessage({ id: 'security.recommendations.r3.p1' }),
          intl.formatMessage({ id: 'security.recommendations.r3.p2' }),
          intl.formatMessage({ id: 'security.recommendations.r3.p3' })
        ]
      }
    ]
  }
}

export default Recommendations
