import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import PageTop from '../../PageTop'
import FundSecure from './FundSecure'
import EmailForm from '../Landing/StartInvest'
import Recommendations from './Recommendations'
import TwoFactor from './TwoFactor'
import getData from './data'

const PageBody = () => {
  const intl = useIntl()
  return (
    <>
      <PageTop {...getData('PageTop', intl)} />
      <FundSecure {...getData('FundSecure', intl)} />
      <TwoFactor {...getData('TwoFactor', intl)} />
      <Recommendations {...getData('Recommendations', intl)} />
      <EmailForm />
    </>
  )
}

export default PageBody
