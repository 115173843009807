import React from 'react'
import PropTypes from 'prop-types'
import { Div, Container, Text, Row, Col } from 'atomize-jnh'

export default function FundSecure(props) {
  FundSecure.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.subtitle,
    descriptions: PropTypes.descriptions
  }

  const { title, subtitle, descriptions } = props

  return (
    <Div p={{ t: '48px' }}>
      <Container>
        <Text textSize="display1" textAlign="center" textColor="#4D68F0">
          {title}
        </Text>
        <Div w={{ xs: '100%', md: '62%' }} p={{ t: '12px' }} m="0 auto">
          <Text textColor="#6B7C93" textAlign="center" fontWeight="400" textSize="subtitle">
            {subtitle}
          </Text>
        </Div>
        <Div p={{ t: '64px', b: '64px' }}>
          <Row justify="center" align="center">
            {descriptions.map((value, index) => {
              return (
                <Col size={{ xs: '12', md: '4' }} key="index">
                  <Div
                    m="0 auto"
                    w={{ xs: '140px' }}
                    h={{ xs: '140px' }}
                    p={value.p}
                    bg="#CCD4FF"
                    rounded="100%">
                    <Div bgImg={value.icon} m="0 auto" bgSize="cover" w={value.w} h={value.h} />
                  </Div>

                  <Text
                    h={{ xs: '75px', md: '0' }}
                    w="90%"
                    m="24px auto"
                    textColor="#6B7C93"
                    textSize="body"
                    textAlign="center">
                    {value.description}
                  </Text>
                </Col>
              )
            })}
          </Row>
        </Div>
      </Container>
    </Div>
  )
}
